export const CHANNEL_OPTIONS = [
    { key: 1, text: 'OpenAI', value: 1, color: 'green' },
    { key: 14, text: 'Anthropic Claude', value: 14, color: 'black' },
    { key: 33, text: 'AWS', value: 33, color: 'black' },
    { key: 3, text: 'Azure OpenAI', value: 3, color: 'olive' },
    { key: 11, text: 'Google PaLM2', value: 11, color: 'orange' },
    { key: 24, text: 'Google Gemini', value: 24, color: 'orange' },
    { key: 28, text: 'Mistral AI', value: 28, color: 'orange' },
    { key: 41, text: 'Novita', value: 41, color: 'purple' },
    { key: 40, text: '字节跳动豆包', value: 40, color: 'blue' },
    { key: 15, text: '百度文心千帆', value: 15, color: 'blue' },
    { key: 17, text: '阿里通义千问', value: 17, color: 'orange' },
    { key: 18, text: '讯飞星火认知', value: 18, color: 'blue' },
    { key: 16, text: '智谱 ChatGLM', value: 16, color: 'violet' },
    { key: 19, text: '360 智脑', value: 19, color: 'blue' },
    { key: 25, text: 'Moonshot AI', value: 25, color: 'black' },
    { key: 23, text: '腾讯混元', value: 23, color: 'teal' },
    { key: 26, text: '百川大Model', value: 26, color: 'orange' },
    { key: 27, text: 'MiniMax', value: 27, color: 'red' },
    { key: 29, text: 'Groq', value: 29, color: 'orange' },
    { key: 30, text: 'Ollama', value: 30, color: 'black' },
    { key: 31, text: '零一万物', value: 31, color: 'green' },
    { key: 32, text: '阶跃星辰', value: 32, color: 'blue' },
    { key: 34, text: 'Coze', value: 34, color: 'blue' },
    { key: 35, text: 'Cohere', value: 35, color: 'blue' },
    { key: 36, text: 'DeepSeek', value: 36, color: 'black' },
    { key: 37, text: 'Cloudflare', value: 37, color: 'orange' },
    { key: 38, text: 'DeepL', value: 38, color: 'black' },
    { key: 39, text: 'together.ai', value: 39, color: 'blue' },
    { key: 42, text: 'VertexAI', value: 42, color: 'blue' },
    { key: 43, text: 'Proxy', value: 43, color: 'blue' },
    { key: 44, text: 'SiliconFlow', value: 44, color: 'blue' },
    { key: 45, text: 'xAI', value: 45, color: 'blue' },
    { key: 8, text: 'CustomChannel', value: 8, color: 'pink' },
    { key: 22, text: '知识库：FastGPT', value: 22, color: 'blue' },
    { key: 21, text: '知识库：AI Proxy', value: 21, color: 'purple' },
    { key: 20, text: 'Proxy：OpenRouter', value: 20, color: 'black' },
    { key: 2, text: 'Proxy：API2D', value: 2, color: 'blue' },
    { key: 5, text: 'Proxy：OpenAI-SB', value: 5, color: 'brown' },
    { key: 7, text: 'Proxy：OhMyGPT', value: 7, color: 'purple' },
    { key: 10, text: 'Proxy：AI Proxy', value: 10, color: 'purple' },
    { key: 4, text: 'Proxy：CloseAI', value: 4, color: 'teal' },
    { key: 6, text: 'Proxy：OpenAI Max', value: 6, color: 'violet' },
    { key: 9, text: 'Proxy：AI.LS', value: 9, color: 'yellow' },
    { key: 12, text: 'Proxy：API2GPT', value: 12, color: 'blue' },
    { key: 13, text: 'Proxy：AIGC2D', value: 13, color: 'purple' }
];
